<template>
  <div
    v-if="isShowModal"
    class="new-child"
  >
    <span class="new-child__close" @click="closeModal"></span>
    <h2 class="new-child__title">Adding a {{ camperStudent }}</h2>
    <div class="new-child__avatar">
      
      <div v-if="avatar" class="new-child__avatar-img" >
        <img 
        :src="avatarPreview" 
        alt="Photo"
        >
      </div>
      <div
        v-else class="new-child__avatar-img" 
        @click="$refs.refInputEl.click()"
      >
      </div>
      
      <div 
        class="new-child__avatar-btn"
        @click="$refs.refInputEl.click()"
      >
        <CameraSVG /><span>Select photo</span>
      </div>
      <input 
        type="file"
        ref="refInputEl" 
        accept=".jpg, .png, .gif"
        :hidden="true"
        @input="handleFileUpload"
      >
    </div>
    

    <label
      class="form__row"
    >
      <span class="form__row-label">First Name *</span>
      <input
        v-model="firstName"
        type="text"
        class="input"
        :class="{'is-invalid': $v.firstName.$dirty && !$v.firstName.required }"
        @input="capitalizeFirstLetter($event.target.value, 'firstName')"
      />
    </label>

    <label
      class="form__row"
    >
      <span class="form__row-label">Last Name *</span>
      <input
        v-model="lastName"
        type="text"
        class="input"
        :class="{'is-invalid': $v.lastName.$dirty && !$v.lastName.required }"
        @input="capitalizeFirstLetter($event.target.value, 'lastName')"
      />
    </label>

    <label
      class="form__row"
    >
      <span class="form__row-label">Date of Birth *</span>
      <date-picker
        v-model="dob"
        input-class="input-date"
        valueType="format"
        placeholder="Select"
        :disabled-date="(date) => date >= new Date()"
        :class="{'is-invalid': $v.dob.$dirty && !$v.dob.required}"
      ></date-picker>
    </label>

    <div
      class="form__row"
      v-if="isEnableSelectGender"
    >
      <span class="form__row-label">Select Gender *</span>
      <label
        class="nice-radio"
      >
        <input
          type="radio"
          v-model="gender"
          value="Male"
        />
        <span>Male</span>
      </label>
      <label
        class="nice-radio"
      >
        <input
          type="radio"
          v-model="gender"
          value="Female"
        />
        <span>Female</span>
      </label>
    </div>

    <div
      class="form__row"
      v-else
    >
      <span class="form__row-label">Gender *</span>
      <label
        class="nice-radio"
      >
        <input
          type="radio"
          v-model="gender"
          :value="gender"
          :disabled="true"
        />
        <span>{{ gender }}</span>
      </label>
    </div>

    <label
      class="form__row"
    >
      <span class="form__row-label">{{ camperStudent }} email (optional)</span>
      <input
        v-model="email"
        type="text"
        class="input"
        :class="{'is-invalid': $v.email.$dirty && !$v.email.email }"
        @input="resetError"
      />
    </label>

    <p class="form__error">{{errorMessage}}</p>

    <button 
      class="btn btn--color-dark form__button"
      @click="saveChild"
      :disabled="isLoading"
    >
      Save
    </button>
  </div>
</template>

<script>
import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'

import axios from '@axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { required, email } from 'vuelidate/lib/validators'
import CameraSVG from '@/assets/images/icons/icon-camera.svg'

  export default {
    components: {
      DatePicker,
      CameraSVG,
    },
    props: {
      isShowModal: {
        type: Boolean,
        default: false,
      },
      isEnableSelectGender: {
        type: Boolean,
        default: true,
      },
      defaultGender: {
        type: String,
        default: 'Male',
      },
      familyName: {
        type: String,
        default: '',
      },
    },
    mixins: [instanceSessionCamperNames],
    data(){
      return {
        isLoading: false,
        avatarPreview: null,
        avatar: null,
        firstName: '',
        lastName: '',
        dob: '',
        gender: 'Male',
        email: '',
        errorMessage: '',
      }
    },
    validations: {
      firstName:  { required },
      lastName:  { required },
      dob: { required },
      gender: { required },
      email: { email },
    },
    watch: {
      isShowModal(val) {
        if (val) {
          this.resetModalData()
        }
      },
    },
    methods: {
      handleFileUpload( e ){
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.addEventListener(
          'load',
          () => {
            this.avatar = file
            this.avatarPreview = reader.result
          },
          false,
        )

        if (file) {
          reader.readAsDataURL(file)
        }
      },
      resetModalData() {
        this.avatarPreview = null
        this.avatar = null
        this.firstName = ''
        this.lastName = this.familyName
        this.dob = ''
        this.gender = this.defaultGender
        this.email = ''
      },
      closeModal() {
        this.resetModalData()
        this.$emit('closeModal')
      },
      resetError() {
        this.errorMessage = ''
      },
      capitalizeFirstLetter(value, field){
        if (value) {
          const [firstLetter, ...rest] = value.split('')
          this[field] = firstLetter.toUpperCase() + rest.join('')
        }
      },
      async saveChild() {
        if (this.$v.$invalid){
          await this.$v.$touch()
          return
        }

        const queryParams = {
          first_name: this.firstName,
          last_name: this.lastName,
          is_student: true,
          extra_user_info: {
            birth_date: this.dob,
            gender: this.gender,
          }
        }
        if (this.email) {
          queryParams.email = this.email
        }
        if (this.avatarPreview) {
          queryParams.avatar = this.avatarPreview
        }
        
        this.isLoading = true
        axios
          .post('/my-children/', queryParams)
          .then(() => {
            this.$emit('updateChildrenList')
            this.isLoading = false
            this.closeModal()
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.isLoading = false
              const validationErrors = Object.values(error.response.data.errors)
              this.errorMessage = validationErrors.flat()
            }
          })
        }
    }
  }
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';

.new-child {
  position: absolute;
  border-radius: 10px;
  padding: 50px;
  width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -20%);
  background: #FFF;
  z-index: 20;
  box-shadow:  0px 0px 0px 9999px rgba(0, 0, 0, 0.5);

  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    top: 20px;
    right: 20px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    &::before,&::after {
      content: '';
      display: block;
      position: absolute;
      left: 6px;
      width: 1px;
      height: 15px;
      background: #000;
      transform: rotate(45deg);
    }
    &::after{
      transform: rotate(-45deg);
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img {
      background: #EDEDED;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 10px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: $primary;
      svg > g > path {
        stroke: $primary;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .new-child {
    position: absolute;
    border-radius: 10px;
    padding: 15px;
    width: 320px;
    left: 50%;
    top: 50%;
    background: #FFF;
    z-index: 20;
    box-shadow:  0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
  }
}
</style>
<style lang="scss">
.mx-datepicker{
  &.is-invalid{
    .input-date {
      border: 1px solid var(--error) !important;
      &::placeholder {
        color: var(--error)
      }
    } 
  }  
}
</style>