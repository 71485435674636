<template>
  <div>
    <div class="family site__centered">
      <h2 class="family__title">Family</h2>
      <div class="family__body">
        <SideNav />
        <div class="family__block">
          <FamilyMember 
            v-for="familyMember in familyList"
            :key="familyMember.id"
            :member="familyMember"
          />
          <button
            @click="addFamilyMemberModal"
            class="btn btn--color-dark family__add-member"
          >
            Add Family member
          </button> 
        </div>
      </div>
    </div>
    <AddFamilyMember
      @addNewChild="showAddNewChildModal"
    />
    <AddNewChildModal
      :is-show-modal="isShowAddNewChildModal"
      :default-gender="defaultGender"
      :family-name="user.last_name"
      @closeModal="isShowAddNewChildModal = false"
      @updateChildrenList="prepareFamilyList"
    />
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import { scrollToTop } from '@/helpers/scrollToTop'
import SideNav from '@/components/site/SideNav.vue'
import FamilyMember from '@/views/Family/FamilyMember.vue'
import AddFamilyMember from '@/views/Family/AddFamilyMember.vue'
import AddNewChildModal from '@/components/site/AddNewChildModal.vue'

export default {
  name: "FamilyList",
  components: {
    FamilyMember,
    SideNav,

    AddFamilyMember,
    AddNewChildModal,
  },
  data() {
    return {
      user: getUserData(),
      isShowAddNewChildModal: false,
      defaultGender: null,
    }
  },
  computed: {
    ...mapGetters({
      familyList: 'family/getFamilyList',
      authSettings: 'authSettings/getAuthSettings',
    }),
  },
  async created() {
    store.commit('SET_LOADING_STATUS', true)
    store.commit('family/SET_FAMILY_ID', this.user.family_id)
    await store.dispatch('family/fetchFamilyList')
    this.defaultGender = this.authSettings.default_gender
    store.commit('SET_LOADING_STATUS', false)
  },
  methods: {
    addFamilyMemberModal() {
      this.$bvModal.show('add-family-member')
    },
    showAddNewChildModal() {
      scrollToTop()
      this.$bvModal.hide('add-family-member')
      this.isShowAddNewChildModal = true
    },
    async prepareFamilyList() {
      store.commit('SET_LOADING_STATUS', true)
      await store.dispatch('family/fetchFamilyList')
      store.commit('SET_LOADING_STATUS', false)
    },
  },
}
</script>

<style scoped>
</style>